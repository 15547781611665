import React from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Popconfirm,message,Popover,Modal,Descriptions,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { DeleteOutlined } from '@ant-design/icons';
import { SearchOutlined,EditOutlined,WalletFilled,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import PaymentForm from '../components/PaymentForm'
import { NavLink } from 'react-router-dom'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var bizuserid=''


class SalesReceiptList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    salesreceipts: [],
    viewpaymentmodal:false,
    receipttotalpayment:0,
    receiptdetails:{},
    userprofile:{},
    datarequested:true,

  };

  callback(key) {
    console.log(key);
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
          sacco= ''
      }
      
      if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
      }else{
          token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

    axios.get(serverconfig.backendserverurl+`/api/salesreceipts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          salesreceipts:res.data
        })
    })

    //getting the user profile
    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })

        this.setState({datarequested:false})
    })
}


////////////////////////////////////////////////////
 /// PAYMENT METHODS BEGIN
 showpaymentmodal=(id)=>{
  //now proceed to get the data for the product
  axios.get(`${serverconfig.backendserverurl}/api/salesreceipts/${id}`)
  .then(res => {  
      this.setState({
        receiptdetails:res.data
      })

    })

  //getting the invoice total payment now
  axios.get(`${serverconfig.backendserverurl}/api/payments/?sale=${id}`)
      .then(res => {  
        const totalPaid =res.data.reduce((totalPaid, item) => totalPaid + item.amount, 0);
        this.setState({
          receipttotalpayment:totalPaid
        })

      })

this.setState({ viewpaymentmodal: true });

}

closepaymentmodal = () => {
this.setState({ viewpaymentmodal: false });
};



  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = text => {
    let form_data = new FormData();
    form_data.append('saleid', text);
    form_data.append('sacco',sacco);
    console.log(text)
 
    //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/deletesale', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
         })
         .then(res =>{
          window.location.reload(false)
          message.info(res.data.message) 
         }
         )
         .catch(error => console.log(error)) 
   };

  render() {

    if(this.state.userprofile.branch_manager){

      var columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },

        {
          title: 'Receipt No',
          dataIndex: 'receipt_number',
          key: 'id',
          ...this.getColumnSearchProps('receipt_number'),
        },
        {
          title: 'Client Tel',
          dataIndex: 'creditor_contact',
          key: 'id',
          ...this.getColumnSearchProps('creditor_contact'),
        },
        {
          title: 'Total',
          dataIndex: 'netAmount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text => 
          <p>
          <Popover content={<p>Click here to edit,print,view sale details</p>} title="Edit Sale">
          <Link to={`/salereceipts/${text}`}><EditOutlined style={{color:'blue',':hover':{color:'white'}}} /></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click to add payment against sale</p>} title="Payment">
            <WalletFilled style={{color:'blue'}} onClick={() => this.showpaymentmodal(text)}/>
          </Popover>        
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete sale</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
          </p>,
        }
  
      ];
    }else{

      var columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Receipt No',
          dataIndex: 'receipt_number',
          key: 'receipt_number',
          ...this.getColumnSearchProps('receipt_number'),
        },
        {
          title: 'Client Tel',
          dataIndex: 'creditor_contact',
          key: 'creditor_contact',
          ...this.getColumnSearchProps('creditor_contact'),
        },
        {
          title: 'Total',
          dataIndex: 'netAmount',
          key: 'netAmount',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text => 
          <p>
          <Popover content={<p>Click to add payment against sale</p>} title="Payment">
            <WalletFilled style={{color:'blue'}} onClick={() => this.showpaymentmodal(text)}/>
          </Popover>        
          </p>,
        }
  
  
      ];

    }
   

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return(
        <div>          
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="SALES RECEIPTS" key="1">
            <Table columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.salesreceipts}
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>

          <br></br>    
          <NavLink to='/makesale/'>
          <Button type="primary"  shape="round" icon={<PlusOutlined />} size={this.state.size}>

            Add Sale
            </Button>

          </NavLink>
            

              {/* Product details modal */}
              <Modal
                  visible={this.state.viewpaymentmodal}
                  title="Receipt Payment"
                  width={600}
                  onCancel={this.closepaymentmodal}
                  footer={[
                    <Button key="back" onClick={this.closepaymentmodal}>
                      Cancel
                    </Button>,
                    ]}
                    >
                 <Descriptions title="">
                    <Descriptions.Item label="Total"><CurrencyFormat value={this.state.receiptdetails.netAmount} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                    <Descriptions.Item label="Paid Amount"><CurrencyFormat value={this.state.receipttotalpayment} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                    <Descriptions.Item label="Due Amount"><CurrencyFormat value={Number(this.state.receiptdetails.netAmount)-Number(this.state.receipttotalpayment)} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                </Descriptions>
                <br></br>
                <PaymentForm saleid={this.state.receiptdetails.id}/>

              </Modal>
        </div>
    )


    }


   
  }
}

export default SalesReceiptList; 
