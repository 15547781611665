import React from 'react';
import { Form, Input,message,Button,Select,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''

class ProductUnitForm extends React.Component {

  state = {
    unitname:'' ,  
    description:'',
    datarequested:false,
  }


  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


   axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  this.setState({datarequested:false})

}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('unitname', this.state.unitname);
    form_data.append('description', this.state.description);
    form_data.append('sacco', sacco);
    axios.post(serverconfig.backendserverurl+'/api/productunits/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>     this.setState({datarequested:false})
  ,window.location.reload(false),message.info('Success fully created unit')
  )
  .catch(error => console.log(error))


  }

  handleunitnameChange= (e) => this.setState({ unitname: e.target.value});
  handledescriptionChange= (e) => this.setState({ description: e.target.value});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <Form onFinish={(event) => this.handleFormSubmit(event)}>
            <FormItem label="Unit Name"
            name="unitname"
            rules={[
              {
                required: true,
                message: 'Please input name',
              },
            ]}          
            >
              <Input  placeholder="Put a name here." value={this.state.unitname} onChange={this.handleunitnameChange} />
            </FormItem>
  
            <FormItem label="Description"
            name="unitdescription"
            rules={[
              {
                required: true,
                message: 'Please input description',
              },
            ]}          
            >
              <Input  placeholder="Details." value={this.state.description} onChange={this.handledescriptionChange} />
            </FormItem>
  
            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );

    }


    
  }

}


export default ProductUnitForm;



