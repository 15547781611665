import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar } from 'antd';
import { Row, Col } from 'antd';
import ProductUnitForm from '../components/ProductUnitForm'
import { UserOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");


var token= ''
var sacco= ''

class ProductUnitDetail extends React.Component{

    state={
        productunit: {}
    }

    componentDidMount(){

        if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        }else{
            sacco= ''
        }
        
        if(localStorage.getItem("token")){
            token= localStorage.getItem("token")
        }else{
            token= ''
        }
    
    
        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
        }
      
        const productunitID=this.props.match.params.productunitID;
        axios.get(`${serverconfig.backendserverurl}/api/productunits/${productunitID}`)
        .then(res => {  
            this.setState({
                productunit: res.data
            })
        })

    }

    handleDelete=(event)=>{
        const productunitID=this.props.match.params.productunitID;
        axios.delete(`${serverconfig.backendserverurl}/api/productunits/${productunitID}`);
        this.props.history.push('/')
    }

    render(){
        return(
            <div>
                <Row>
                <Col span={8}>
                <Card title={this.state.productunit.unitname}>
                    <p>Desc: {this.state.productunit.description}</p>
                </Card>
                </Col>
                <Col span={2}>
             
                </Col>
                <Col span={8}>
                <Card title="More Action(Update/Delete)">
                  <h4>Product Unit Update</h4>
                    <ProductUnitForm 
                    requestType="put"
                    productunitID={this.props.match.params.productunitID}
                    btnText="Update"
                    />
                    <br/>
                    <h4>Product Unit Delete</h4>
                    <Button  onClick={this.handleDelete} type="danger" htmlType="button">Delete</Button>

                </Card>
                </Col>
                </Row>

            </div>
        )
    }
}

export default ProductUnitDetail; 