import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});




var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

  render() {
    return (
      <div style={{padding:20}}>
            
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          <h3 style={{color:'black'}}>{this.state.companyprofile.sacco_name}-{this.state.companyprofile.branchname}</h3>
          <h6 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }
          {
          this.state.companyprofile.RegisteredPhoneNumber3==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber3
          }
          </h6>               
          <h5 style={{color:'black'}}>Email: {this.state.companyprofile.company_email}</h5>
          <h5 style={{color:'black'}}>Wesite: {this.state.companyprofile.website}</h5>
          <h5 style={{color:'black'}}>{this.state.companyprofile.box_number}</h5>
           <h4 style={{color:'black'}}>SLIP NO: {this.props.slipnumber}</h4>
           <h4 style={{color:'black'}}>DATE: {this.props.date}</h4>
           <h5 style={{color:'black'}}>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
           <h3 style={{strong:true,color:'black',fontWeight:'bolder'}}>PRODUCT-RECEIVED RECEIPT</h3>

           <table cellpadding="2" cellSpacing="2" border="1">
            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.AccountNumber}</td>
            </tr>

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NAME</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.FullName}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>RATE</td>
              <td style={{fontWeight:'bold'}}>{this.props.productrate}</td>
            </tr>            

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>QUANTITY</td>
              <td style={{fontWeight:'bold'}}>{this.props.quantity} {this.props.productunit}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>PRODUCT AMOUNT</td>
              <td style={{fontWeight:'bold'}}><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>In Words</td>
              <td style={{fontWeight:'bold'}}>{ toWords.convert(this.props.totalamount)}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>BROUGHT IN BY</td>
              <td style={{fontWeight:'bold'}}>{this.props.paidinby}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>SLIP NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.slipnumber}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>CONTACT</td>
              <td style={{fontWeight:'bold'}}>{this.props.transactorcontact}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>TELLER</td>
              <td style={{fontWeight:'bold'}}>{username}</td>
            </tr> 



           </table>


        
    </div>
    );
  }
}



class ProductAccountDepositView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    slipnumber:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    lastslipnumber:0,
    savingsamount:0,
    sharesamount:0,
    welfareamount:0,
    emergencyamount:0,
    dreamamount:0,
    legacyamount:0,
    childrenamount:0,
    universityamount:0,
    companyprofile:{},
    accountchange:false,
    userprofile:{},

    quantity:0,
    selectedproduct:'',
    selectedproductobject:{},
    products:[],
    productrate:0
  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/products/?sacco=${sacco}`)
    .then(res => {
      console.log(res.data)
        this.setState({
            products:res.data
        })

    })


    //get last slip number
    let form_data = new FormData();
    form_data.append('sacco', sacco);
    axios.post(serverconfig.backendserverurl+'/customqueries/getsaccolastslipnumber', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({lastslipnumber:res.data.lastnumber})
        this.setState({slipnumber:Number(res.data.lastnumber)+1})

        this.setState({datarequested:false})
    })
    .catch(error => console.log(error))


  }

  

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
      })

      axios.get(`${serverconfig.backendserverurl}/api/productdeposits/?member=${e}`)
      .then(res => {  
          this.setState({previoustransactions: res.data.slice(0, 3)})
          console.log(res.data)
          this.setState({accountchange:false});
      })

      this.setState({slipnumber:Number(this.state.lastslipnumber)+1})

  }

  //handle selected product change
  handleselectedproductChange= (e) =>{
   this.setState({datarequested:true})

    this.setState({ selectedproduct: e});
    axios.get(`${serverconfig.backendserverurl}/api/products/${e}`)
    .then(res => {  
        this.setState({datarequested:false})
        this.setState({selectedproductobject: res.data})
        this.setState({productrate:res.data.unit_price})
    })

  }

  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handleslipnumberChange= (e) => this.setState({ slipnumber: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});

  handlequantityChange= (e) => this.setState({ quantity: e.target.value});

  //sum up all the 
  calculateTotalDeposit=()=>{
    var total=Number(this.state.productrate)*Number(this.state.quantity)
    return total;
  }


  //submit the deposit now
  handleFormSubmit=(event) =>{
    
    if (this.state.slipnumber===''){
      message.info("Slip number cant be empty")
    }else if(Number(this.calculateTotalDeposit())<Number(this.state.selectedaccountobject.minimum_account_deposit)){
      message.info("Minimum account deposit is: "+this.state.selectedaccountobject.minimum_account_deposit)
    }else{
      this.setState({datarequested:true})
      console.log(username)
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('paidinby', this.state.paidinby);
      form_data.append('paidamount', this.calculateTotalDeposit());
      form_data.append('quantity', this.state.quantity);
      form_data.append('productrate', this.state.productrate);
      form_data.append('unit', this.state.selectedproductobject.unitname);
      form_data.append('productname', this.state.selectedproductobject.product_name);

      form_data.append('slipnumber', this.state.slipnumber);
      form_data.append('transactorcontact', this.state.transactorcontact);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('username', username);
      form_data.append('userid', bizuserid);
      form_data.append('productid', this.state.selectedproductobject.id);

      axios.post(serverconfig.backendserverurl+'/customqueries/createproductaccountdeposit', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    }
    
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    const transactioncolumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
      },
      {
        title: 'Slip No',
        dataIndex: 'slip_number',
        key: 'id',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'id',
        render:(text,record)=><h6>{record.quantity} {record.unitname}</h6>

      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

      {
        title: 'Total',
        dataIndex: 'total',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

      
    ];



    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.calculateTotalDeposit()} 
            slipnumber={this.state.slipnumber} 
            paidinby={this.state.paidinby} 
            transactorcontact={this.state.transactorcontact}
            date={this.state.date}
            quantity={this.state.quantity}
            selectedproductobject={this.state.selectedproductobject}
            productrate={this.state.productrate}
            productunit={this.state.selectedproductobject.unitname}

            ref={el => (this.componentRef = el)} /> 
            </div>

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Deposited "
                    subTitle="Please print receipt or finish off transaction"
                    extra={[
                      <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 >Print Receipt</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentRef}
                          />  
                          </a>,
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                    </Card>

                    </Col>

            </Row>


          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Account To:</h4>
                    <FormItem label="Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                             
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }

                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Account Balance:<CurrencyFormat value={this.state.selectedaccountobject.productdebt_balance} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
  
                    <br></br>
                    <h3 style={{display:'flex',flexDirection:'row',fontWeight:'bold',alignSelf:'center',color:'#154360'}}>PRODUCT DEPOSIT FORM</h3>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                  >

                <FormItem label={<h6 style={{fontWeight:'bold'}}>Product</h6>}>
                    <Select 
                    placeholder="Product Name" 
                    style={{  }} 
                    value={this.state.selectedproduct}
                    onChange={this.handleselectedproductChange} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}                     
                    >

                      {this.state.products.map(
                        (prod)=>(
                          <Option value={prod.id}>{prod.product_name} @ {prod.unit_price}</Option>
                        ))}
                    </Select>
                </FormItem>  


                <FormItem label={<h6 style={{fontWeight:'bold'}}>Product Quantity </h6>}
                  name="product quantity"
                  rules={[
                    {
                      required: false,
                      message: 'Please input quantity',
                    },
                  ]}
                
                >
                <Input name="quantity"  placeholder="Quantity" value={this.state.quantity} onChange={this.handlequantityChange}/>
                </FormItem>

                    <FormItem label={<h4 style={{fontWeight:'bold',color:'#154360'}}>Total Amount </h4>}
                    >
                      <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={this.calculateTotalDeposit()} displayType={'text'} thousandSeparator={true} /></h3>
                   
                    </FormItem>
  
                    <FormItem label={<h4 style={{fontWeight:'bold'}}>{this.state.selectedproductobject.product_name} Brought By:</h4>}
                    name="paidinby"
                    rules={[
                      {
                        required: true,
                        message: 'Please input name',
                      },
                    ]}
                    
                    >
                      <Input name="paidinby"  placeholder="Name" value={this.state.paidinby} onChange={this.handlepaidinbyChange}/>
     
                    </FormItem>
  
                    <FormItem 
                      label={<h4 style={{fontWeight:'bold'}}> Contact:</h4>}
                      >
                    <PhoneInput
                      countrySelectProps={{ unicodeFlags: true }}
                      defaultCountry="UG"
                      placeholder="Enter phone"
                      value={this.state.transactorcontact}
                      onChange={this.handletransactorcontactChange}
                      />
                  </FormItem> 
  
                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Slip Number:</h4>}                    
                    >
                      <Input name="slipnumber" type='number' placeholder="0" value={this.state.slipnumber} onChange={this.handleslipnumberChange}/>
                  </FormItem>

                  <FormItem>
                    <Button  type="primary" htmlType="submit">Submit</Button>
                  </FormItem>
                  </Form>
  
                    </Card>
                   
                  </Col>
  
                 <Col xs="12" sm="6" lg="6">
                   <Card>
                   <h3 style={{fontWeight:'bold',color:'#154360'}}>Previous Entries</h3>
                   <div style={{overflowX:'auto'}}>
                   <Table columns={transactioncolumns} pagination={{hideOnSinglePage:true,responsive:true,pageSize:1000}} dataSource={this.state.previoustransactions}  bordered/>
                   </div>

                   <br></br>
                   <h4>Last Transaction Slip: {this.state.lastslipnumber}</h4>
                   </Card>

                </Col>
            </Row>
  
            </div>
   
       </div>              
                      
        )
      }

    }
   
  }
}

export default ProductAccountDepositView; 
