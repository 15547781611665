import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
          sacco= ''
      }
      
      if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
      }else{
          token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }


  render() {
    return (
      <div style={{padding:20}}>
            
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          <h3 style={{color:'black'}}>{this.state.companyprofile.sacco_name}</h3>
          <h6 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }
          {
          this.state.companyprofile.RegisteredPhoneNumber3==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber3
          }
          </h6>               
          <h5 style={{color:'black'}}>Email: {this.state.companyprofile.company_email}</h5>
          <h5 style={{color:'black'}}>Wesite: {this.state.companyprofile.website}</h5>
          <h5 style={{color:'black'}}>{this.state.companyprofile.box_number}</h5>
           <h4 style={{color:'black'}}>RECEIPT: {this.props.receipt_number}</h4>
           <h4 style={{color:'black'}}>DATE: {this.props.date}</h4>
           <h5 style={{color:'black'}}>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
           <h3 style={{strong:true,color:'black'}}>SALES RECEIPT</h3>

           <table cellpadding="2" cellSpacing="2" border="1">
           <tr>
                <th>
                ITM                 
                </th> 
                <th >
                QTY                 
                </th>   
                <th >
                RTE                 
                </th>     
                <th>
                AMT                 
                </th>                                                                
              </tr>

              {
                this.props.receiptitems.map(
                  (item)=>(
                    <tr style={{height:40,padding:1}} key={item.id}>
                      <td>{item.item}</td>
                      <td>{item.quantity}</td>
                      <td>{item.rate}</td>
                      <td>{item.amount}</td>
                    </tr>
                  ))
              }
           </table>
          <br></br>
          <div>
            <h6 style={{color:'black'}}>VAT({this.props.vatpercentage+" %"}): <CurrencyFormat value={this.props.totalTax} displayType={'text'} thousandSeparator={true} /></h6>
            <h6 style={{color:'black'}}>PAID:<CurrencyFormat  value={this.props.amountpaid} displayType={'text'} thousandSeparator={true} /></h6>
            <h6 style={{color:'black'}}>BALANCE: <CurrencyFormat  value={this.props.receiptbalance} displayType={'text'} thousandSeparator={true} /></h6>
            <h6 style={{color:'black'}}>SUB TOTAL: <CurrencyFormat  value={this.props.receipttotal} displayType={'text'} thousandSeparator={true}  /></h6>
            <h5>TOTAL: <CurrencyFormat style={{color:'black'}} value={this.props.netAmount} displayType={'text'} thousandSeparator={true} /></h5>  
          </div>
            
    </div>
    );
  }
}



class SalesReceiptView extends React.Component {

  state = {
    receiptItem:{},
    receiptitems:[],
    item:'',
    quantity:0,
    rate:0,
    amount:0,   
    products:[],
    date:moment().format(dateFormat).toString(),
    product:'',
    pricecategories:[],
    pricecategory:'',
    pricecategoryitems:{},
    amounttobepaid:0,
    stock:'',
    unit:'',
    size: 'medium',
    scanresult:'',
    scannervisible: "hidden",
    scannerdisplay: "none",
    scanneropen:false,
    scannerbtntxt:"Open Scanner",
    productname:"",
    cleintphone:"",
    clientname:"",
    receipttotal:0,
    receiptbalance:0,
    amountpaid:0,
    autoreceiptno:moment().format("DDMMYYYYHHmmss").toString(),
    manualreceiptno:'',
    itemnumber:0,
    receipt_number:'',
    receipttype:'',
    vatpercentage:0,
    netAmount:0,
    totalTax:0,
    datarequested:true,


    ////////////////////////////////
    //payments form variables
    paymentmode:'',
    amount:'',
    comments:'',
    phonenumberfrom:'',
    phonenumberto:'',
    accountto:'',
    clientbank:'',
    transreference:'',
    chequeno:'',   
    viewpaymentmodal:false, 

    editvisible:false,
    editableid:''

  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
          sacco= ''
      }
      
      if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
      }else{
          token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


    axios.get(serverconfig.backendserverurl+`/api/products/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          products:res.data
        })
    })

        let form_data = new FormData();
        form_data.append('sacco',sacco);

         //Now get the last estimate number in db
         axios.post(serverconfig.backendserverurl+'/customqueries/getlastreceiptnumber',form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => {
        this.setState({
          receipt_number: Number(res.data.lastnumber)+1
        })
      }
      )
      .catch(error => console.log(error)) 

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            vatpercentage: res.data.vatpercentage
          })
    
          this.setState({datarequested:false})
      })

  }

  /////////////////////////////////////////////
  // MODAL METHODS BEGIN HERE

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  //Item addition success
  handleOk = () => {
    //increase item number
    this.setState({itemnumber:(Number(this.state.itemnumber)+1)})

    //set An item now
    this.setState({receiptItem:{
      item:this.state.productname,
      rate:this.state.rate,
      quantity:this.state.quantity,
      amount:this.state.amounttobepaid,
    }})

    const newreceiptitem={
      key:uuid(),
      itemnumber:(Number(this.state.itemnumber)+1),
      item:this.state.productname,
      productid:this.state.product,
      rate:this.state.rate,
      quantity:this.state.quantity,
      amount:this.state.amounttobepaid,
      pricing:this.state.pricecategory,
      unit:this.state.unit,
     }


    //add to the receipt item list
     this.setState({
      receiptitems: [...this.state.receiptitems, newreceiptitem]
      });

      message.info(this.state.productname+' added to receipt');

      this.setState({ visible: false });

  };

  handleCancel = () => {
    this.setState({ visible: false });
  };


  showeditModal = (id) => {
    this.setState({editableid:id});
    this.setState({editvisible: true});

    this.state.receiptitems.map(
      (item)=>{
        if(item.key==id){
          this.setState({productname:item.item});
          this.setState({product:item.productid});
          this.setState({rate:item.rate});
          this.setState({quantity:item.quantity});
          this.setState({amounttobepaid:item.amount});
          this.setState({pricecategory:item.pricing});
          this.setState({unit:item.unit});
        }

      })

  };

  //Item addition success
  handleeditOk = () => {

     const elementsIndex = this.state.receiptitems.findIndex(element => element.key == this.state.editableid )

     let newArray = [...this.state.receiptitems]

     newArray[elementsIndex] = {...newArray[elementsIndex], 
      item:this.state.productname,
      productid:this.state.product,
      rate:this.state.rate,
      quantity:this.state.quantity,
      amount:this.state.amounttobepaid,
      pricing:this.state.pricecategory,
      unit:this.state.unit,
    }

    this.setState({
      receiptitems: newArray,
      });

      message.info(this.state.productname+' updated');
      this.setState({ editvisible: false });
    };


  handleeditCancel = () => {
    this.setState({ editvisible: false });
  };


  /////////////////////////////////////////////////
  // FORM DATA METHODS
  handledatechange= (date, dateString) => this.setState({ date: dateString});

  handleproductChange= (e) =>{
    this.setState({ product: e});
      //getting the product sub categories prices and rest categories
      this.setState({pricecategories:[]});
      this.setState({ pricecategory: ''});
      this.setState({ amounttobepaid: 0});
      this.setState({ quantity: 0});
      this.setState({ rate: 0});

      axios.get(`${serverconfig.backendserverurl}/api/specialprices?product=${e}`)
      .then(res => {
          this.setState({
            pricecategories:res.data
          })
      })

      // getting the product items
      axios.get(`${serverconfig.backendserverurl}/api/products/${e}`)
      .then(res => {  
          this.setState({rate:res.data.unit_price})
          this.setState({stock:res.data.available_stock})
          this.setState({unit:res.data.unitname})
          this.setState({productname:res.data.product_name})
      })


  } 

  handlepricecategoryChange= (e) =>{

    // set the pricing name
    this.state.pricecategories.map(
      (ctgry)=>{
        e==ctgry.id?
        this.setState({ pricecategory: ctgry.name+" pricing"})
        :
        console.log("normal pricing")
      })

    //setting the rate, quantity etc
    e==0?
    this.setState({ pricecategory:"Normal pricing"})
    :
    //set the pricecategoryname
    axios.get(`${serverconfig.backendserverurl}/api/specialprices/${e}`)
    .then(res => {
        this.setState({
          pricecategoryitems:res.data
        })

        //setting the rate and qunatity
        this.setState({amounttobepaid:this.state.pricecategoryitems.amount});
        this.setState({quantity:this.state.pricecategoryitems.quantity});
    })

  }

  handlequantityChange= (e) => this.setState({ quantity: e.target.value});
  handlerateChange= (e) => this.setState({ rate: e.target.value});
  handlecleintphoneChange= (e) => this.setState({ cleintphone: e});
  handleclientnameChange= (e) => this.setState({ clientname: e.target.value});
  handleamountpaidChange=(e)=>this.setState({amountpaid:e});
  handlereceipt_numberchange= (e) => this.setState({ receipt_number: e.target.value});
  handlereceipttypeChange= (e) => this.setState({ receipttype: e});
  handlevatpercentageChange= (e) => this.setState({ vatpercentage: e.target.value});
  
  handleamounttobepaidChange= (e) =>{
    this.setState({ amounttobepaid: e.target.value});
  } 


  calculateamounttobepaid=()=>{
    this.state.pricecategory=="Normal pricing"?
    this.setState({amounttobepaid:Number(this.state.rate)*Number(this.state.quantity)})
    :
    console.log("not supposed")
  }

  //////////////////////////////////////////////////////
  // SCANNER METHODS
  handleScan = data => {
    if (data) {
      //set the scanned result
      this.setState({
        scanresult: data
      })

      //Query the system using the code now
      this.setState({scannervisible: "hidden"})
      this.setState({scannerdisplay: "none"})
      this.setState({scanneropen:false})
      this.setState({scannerbtntxt:"Open Scanner"})

      // getting the product items
      //make a query to database to get 
      this.setState({pricecategories:[]});
      this.setState({ pricecategory: ''});
      this.setState({ amounttobepaid: 0});
      this.setState({ quantity: 0});
      this.setState({ rate: 0});

      axios.get(`${serverconfig.backendserverurl}api/products?product_serial=${this.state.scanresult}`)
      .then(res => {  
          this.setState({rate:res.data[0].unit_price})
          this.setState({stock:res.data[0].available_stock})
          this.setState({unit:res.data[0].unitname})
          this.setState({productname:res.data[0].product_name})

          //query for the product price categories
          axios.get(`${serverconfig.backendserverurl}/api/specialprices?product=${res.data[0].id}`)
          .then(res => {
              this.setState({
                pricecategories:res.data
              })
          })

      })

    }
  }

  handleError = err => {
    console.error(err)
  }

  scanSerial=()=>{
    if (this.state.scanneropen==false){
      this.setState({scannervisible: "visible"})
      this.setState({scannerdisplay: "block"})
      this.setState({scanneropen:true})
      this.setState({scannerbtntxt:"Close Scanner"})
    }else{
      this.setState({scannervisible: "hidden"})
      this.setState({scannerdisplay: "none"})
      this.setState({scanneropen:false})
      this.setState({scannerbtntxt:"Open Scanner"})
    }

  }
  //////////////////////////////////////////
  // OTHER RECEIPT OPERATIONS
  clearReceipt=()=>{
    this.setState({receiptitems:[]})
  }

  removeItem=(id)=>{
    console.log(id)
    this.setState({ receiptitems:  [...this.state.receiptitems.filter(todo => todo.key
      !==id)]});
  }

  calculatereceipttotal=()=>{
 
    if(this.state.receipttype=="tax"){
      const itemstotal = this.state.receiptitems.reduce((totalItems, item) => totalItems + item.amount, 0);
      const totTax=((this.state.vatpercentage/100)*itemstotal)
  
      this.setState({receipttotal:itemstotal})
      //getting net total and total tax
      this.setState({totalTax:totTax})
      //setting the net amount
      this.setState({netAmount:Number(itemstotal)+Number(totTax)})

      this.setState({receiptbalance:Number(this.state.netAmount)-Number(this.state.amountpaid)})

    }else{
      const itemstotal = this.state.receiptitems.reduce((totalItems, item) => totalItems + item.amount, 0);
      const totTax=0
  
      this.setState({receipttotal:itemstotal})
      //getting net total and total tax
      this.setState({totalTax:totTax})

      //setting the net amount
      this.setState({netAmount:Number(itemstotal)+Number(totTax)})
      this.setState({receiptbalance:Number(this.state.netAmount)-Number(this.state.amountpaid)})

    }

  }

  /////////////////////////////////////////////
  //////////////////////////////////////
  // MAKE FINAL RECEIPT SUBMISSION
  submitSale=()=>{
      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('clientname', this.state.clientname);
      form_data.append('cleintphone', this.state.cleintphone);
      form_data.append('receipttotal', this.state.receipttotal);
      form_data.append('receiptbalance', this.state.receiptbalance);
      form_data.append('amountpaid', this.state.amountpaid);
      form_data.append('user', "");
      form_data.append('products', JSON.stringify(this.state.receiptitems));
      form_data.append('receipt_number', this.state.receipt_number)
      form_data.append('receipttype', this.state.receipttype)
      form_data.append('netAmount', this.state.netAmount)
      form_data.append('totalTax', this.state.totalTax)
      form_data.append('vatpercentage', this.state.vatpercentage)

      form_data.append('comments', this.state.comments);
      form_data.append('phonenumberfrom', this.state.phonenumberfrom);
      form_data.append('phonenumberto', this.state.phonenumberto);
      form_data.append('accountto',this.state.accountto);
      form_data.append('clientbank',this.state.clientbank);
      form_data.append('transreference', this.state.transreference);
      form_data.append('chequeno',this.state.chequeno);
      form_data.append('sacco',sacco);
      form_data.append('saletype',"localsale");
      form_data.append('request_number',"");

      if(this.state.receipttype==="" ||this.state.netAmount===0){
        alert("receipt type missing or no products in list")
      }else{
        this.setState({datarequested:true})
      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/createsale', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res =>{
        this.setState({datarequested:false})

        message.info('Successfully made Sale')
        this.setState({receiptitems:[]})
        this.setState({netAmount:0})
        this.setState({amountpaid:0})
        this.setState({receiptbalance:0})
        this.setState({amountpaid:0})

        window.location.reload(false)

      } 
      )
      .catch(error => console.log(error))

      }

  }

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  showPaymentModal=()=>{
    this.setState({ viewpaymentmodal: true });
    
  }
  
  closepaymentmodal = () => {
    this.setState({ viewpaymentmodal: false });
   };

   handlepaymentmodeChange= (e) => {
    this.setState({paymentmode:e})
 }

   onPaymentFinish=(event) =>{
    var paymentmode=event.paymentmode;
    var amount=event.amount;
    var comments=event.comments;
    var phonenumberfrom=event.phonenumberfrom;
    var phonenumberto=event.phonenumberto;
    var accountto=event.accountto;
    var clientbank=event.clientbank;
    var transreference=event.transreference;
    var chequeno=event.chequeno;
  
    this.setState({paymentmode: paymentmode});
    this.setState({ amountpaid: amount});
    this.setState({comments:comments});
    this.setState({phonenumberfrom:phonenumberfrom});
    this.setState({phonenumberto: phonenumberto});
    this.setState({accountto:accountto});
    this.setState({clientbank:clientbank});
    this.setState({transreference: transreference});
    this.setState({chequeno:chequeno});
  
    this.setState({ viewpaymentmodal: false });
  
    }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    const columns = [
      {
        title: '#',
        dataIndex: 'itemnumber',
        key: 'key',
      }, 
      {
        title: 'ITEM',
        dataIndex: 'item',
        key: 'item',
      },
      {
        title: 'QUANTITY',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'UNIT',
        dataIndex: 'unit',
        key: 'key',
      },
      {
        title: 'RATE',
        dataIndex: 'rate',
        key: 'rate',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'ACTION',
        dataIndex: 'key',
        key: 'key',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit item</p>} title="Edit Item">
        <EditOutlined style={{color:'blue'}} onClick={(event) => this.showeditModal(text)} />
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete item</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={(event) => this.removeItem(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>
      },

    ];

    const receiptcolumns = [
      {
        title: 'ITEM',
        dataIndex: 'item',
        key: 'item',
      },
      {
        title: 'QUANTITY',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'RATE',
        dataIndex: 'rate',
        key: 'rate',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="SALES ENTRY" key="1">

            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            receipttotal={this.state.receipttotal} 
            amountpaid={this.state.amountpaid} 
            receiptbalance={this.state.receiptbalance} 
            receipttotal={this.state.receipttotal} 
            receipt_number={this.state.receipt_number}
            date={this.state.date}
            columns={receiptcolumns}
            receiptitems={this.state.receiptitems}
            netAmount={this.state.netAmount}
            receipttype={this.state.receipttype}
            vatpercentage={this.state.vatpercentage}
            totalTax={this.state.totalTax}
           
           ref={el => (this.componentRef = el)} /> 
          </div>

          <Row >
                <Col xs="12" sm="6" lg="6">
                <Form  onMouseOver={this.calculatereceipttotal}>
                <FormItem label="Date">
                          <DatePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem label="Client Tel">
                <PhoneInput
                      countrySelectProps={{ unicodeFlags: true }}
                      defaultCountry="UG"
                      placeholder="Tel"
                      value={this.state.cleintphone} onChange={this.handlecleintphoneChange}/>
                </FormItem> 

                <FormItem label="Client Name">
                  <Input  placeholder="Client name" value={this.state.clientname} onChange={this.handleclientnameChange} />
                </FormItem>
                </Form>               

                </Col>

                <Col xs="12" sm="6" lg="6">
                
                    <FormItem label="Receipt No">
                    <Input  placeholder="Receipt No" value={this.state.receipt_number} onChange={this.handlereceipt_numberchange} />
                    </FormItem>
                    
                    <FormItem label="Receipt Type">
                      <Select placeholder="Type"  value={this.state.receipttype} onChange={this.handlereceipttypeChange} >                
                          <Option value='tax'>Tax</Option>
                          <Option value='notax'>No Tax</Option>
                      </Select>
                    </FormItem>
                    {
                      this.state.receipttype=="tax"?
                        <FormItem label="Vat (%)">
                        <Input placeholder="%" style={{width: 80}}
                        value={this.state.vatpercentage} onChange={this.handlevatpercentageChange}
                        />
                      </FormItem>
                      :
                      <br></br>
                    }
              
                
                </Col>
          </Row>

           <br></br>
           <div onMouseOver={this.calculatereceipttotal}>
           <Table columns={columns} 
           dataSource={this.state.receiptitems}
           scroll={{ x: 1000 }}
           bordered/>
           </div>

            <br></br>
            <div style={{overflowX:'auto'}} onMouseOver={this.calculatereceipttotal}>
                <reactstrp.Table>
                    <tbody>
                    <tr>
                    
                      <td>
                      <div>
                        <Tooltip title="Enter Paid Amount" placement="top">
                        <h6>PAID:</h6>
                        <Input
                          value={this.state.amountpaid}
                          onChange={this.handleamountpaidChange}
                          onClick={this.showPaymentModal}
                        />               
                        </Tooltip>
                      </div>
                      </td>
                      <td>
                      <div >
                        <Tooltip title="Recipt Balance" placement="top">
                        <h6>BALANCE:  </h6>
                         <h5 style={{color:'green'}}><CurrencyFormat value={this.state.receiptbalance} displayType={'text'} thousandSeparator={true} /></h5>
                        </Tooltip>
                      </div>
                      </td>

                      <td>
                      <div>
                        <h6>Total: <CurrencyFormat style={{color:'green'}} value={this.state.receipttotal} displayType={'text'} thousandSeparator={true}  /></h6>
                        <h6>VAT({this.state.vatpercentage+" %"}): <CurrencyFormat style={{color:'green'}} value={this.state.totalTax} displayType={'text'} thousandSeparator={true} /></h6>
                        <h5 style={{backgroundColor:'#C0C0C0',color:'#3B9C9C'}}>&nbsp;Net Amount: <CurrencyFormat style={{color:'black'}} value={this.state.netAmount} displayType={'text'} thousandSeparator={true} /></h5>  
                      </div>
                      </td> 
                                          
                    </tr>
                  
                  </tbody>
                </reactstrp.Table>  
              </div>

            <br></br>

            <div style={{overflowX:'auto'}} onMouseOver={this.calculatereceipttotal}>
            <reactstrp.Table>
                    <tbody>
                    <tr>
                    <td>
                      <div>
                        <Tooltip title="Click to add Item to List" placement="top">
                        <p ><PlusSquareFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.showModal}/></p>
                        <h6 >Add Item</h6>
                        </Tooltip>
                      </div>
                      </td>
                      <td>
                      <div >
                        <Tooltip title="Click to clear List" placement="top">
                        <p ><CloseCircleFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.clearReceipt}/></p>
                        <h6 >Clear List</h6>
                        </Tooltip>
                      </div>
                      </td>
                      <td>
                        <a>
                          <ReactToPrint
                            trigger={() =>                     
                              <div>
                                <Tooltip title="Click to print" placement="top">
                                <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                                <h6 >Print</h6>
                                </Tooltip>
                              </div>                          
                          }
                          content={() => this.componentRef}
                        />  
                        </a>
                    
                      </td>
                      <td>
                      <div>
                        <Tooltip title="Click to save sale" placement="top">
                        <p ><SaveOutlined style={{ fontSize: '40px', color: '#08c' }} onClick={this.submitSale}/></p>
                        <h6 >Save</h6>
                        </Tooltip>
                      </div>
                      </td>
                  
                    </tr>
                  
                  </tbody>
                </reactstrp.Table>  
              </div>

                <Modal
                  visible={this.state.visible}
                  title="Sale Item Form"
                  width={600}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  footer={[
                    <Button key="back" onClick={this.handleCancel}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary"  onClick={this.handleOk}>
                      Add
                    </Button>,
                    ]}
                    >
                    <FormItem label="Select Product">
                        <Select 
                        placeholder="Product" 
                        value={this.state.product} 
                        onChange={this.handleproductChange}
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }                              
                        >
                          {this.state.products.map(
                            (prod)=>(
                              <Option value={prod.id}>{prod.product_name}</Option>
                            ))}
                        </Select>
                    </FormItem>
                                    
                    <Row  onMouseOver={this.calculateamounttobepaid}>
                          <Col xs="12" sm="6" lg="5">
                            <FormItem label="Price Categories">
                                <Select placeholder="Price Categories" style={{ width: 120 }} value={this.state.pricecategory} onChange={this.handlepricecategoryChange} >
                                  <Option value={0}>Normal Price</Option>
                                  {this.state.pricecategories.map(
                                    (ctgry)=>(
                                      <Option value={ctgry.id}>{ctgry.name}</Option>
                                    ))}
                                </Select>
                            </FormItem>                       
                          </Col>
                          <Col xs="12" sm="6" lg="6">
                          <FormItem label="Quantity">
                            <Input name="quantity" type="number"  placeholder="Quantity" value={this.state.quantity} onChange={this.handlequantityChange}/>
                          </FormItem>
                          </Col>
                    </Row>                     

                    <Row  onMouseOver={this.calculateamounttobepaid}>
                          <Col xs="12" sm="6" lg="5">
                          <FormItem label="Rate">
                            <Input name="rate" type="number"  placeholder="Rate" value={this.state.rate} onChange={this.handlerateChange}/>
                          </FormItem>                     
                          </Col>
                          <Col xs="12" sm="6" lg="6">
                          <FormItem label="Amount">
                            <Input name="amounttobepaid" type="number"  placeholder="Amount to be paid" value={this.state.amounttobepaid} onChange={this.handleamounttobepaidChange} readOnly/>
                          </FormItem>
                          </Col>
                    </Row> 
                  
                  </Modal>

                  <Modal
                  visible={this.state.editvisible}
                  title="Item Edit Form"
                  width={600}
                  onOk={this.handleeditOk}
                  onCancel={this.handleeditCancel}
                  footer={[
                    <Button key="back" onClick={this.handleeditCancel}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary"  onClick={this.handleeditOk}>
                      Update
                    </Button>,
                    ]}
                    >
                    <FormItem label="Select Product">
                        <Select 
                        placeholder="Product" 
                        value={this.state.product} 
                        onChange={this.handleproductChange}
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }                              
                        >
                          {this.state.products.map(
                            (prod)=>(
                              <Option value={prod.id}>{prod.product_name}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    <br></br>                 
                    <Row  onMouseOver={this.calculateamounttobepaid}>
                          <Col xs="12" sm="6" lg="5">
                            <FormItem label="Price Categories">
                                <Select placeholder="Price Categories" style={{ width: 120 }} value={this.state.pricecategory} onChange={this.handlepricecategoryChange} >
                                  <Option value={0}>Normal Price</Option>
                                  {this.state.pricecategories.map(
                                    (ctgry)=>(
                                      <Option value={ctgry.id}>{ctgry.name}</Option>
                                    ))}
                                </Select>
                            </FormItem>                       
                          </Col>
                          <Col xs="12" sm="6" lg="6">
                          <FormItem label="Quantity">
                            <Input name="quantity" type="number"  placeholder="Quantity" value={this.state.quantity} onChange={this.handlequantityChange}/>
                          </FormItem>
                          </Col>
                    </Row>                     

                    <Row  onMouseOver={this.calculateamounttobepaid}>
                          <Col xs="12" sm="6" lg="5">
                          <FormItem label="Rate">
                            <Input name="rate" type="number"  placeholder="Rate" value={this.state.rate} onChange={this.handlerateChange}/>
                          </FormItem>                     
                          </Col>
                          <Col xs="12" sm="6" lg="6">
                          <FormItem label="Amount">
                            <Input name="amounttobepaid" type="number"  placeholder="Amount to be paid" value={this.state.amounttobepaid} onChange={this.handleamounttobepaidChange} readOnly/>
                          </FormItem>
                          </Col>
                    </Row> 
                  
                  </Modal>



            </Panel>

          </Collapse>



             {/* expense payment modal */}
   <Modal
          visible={this.state.viewpaymentmodal}
          title="Sale Payment"
          width={600}
          onCancel={this.closepaymentmodal}
          footer={[
            <Button key="back" onClick={this.closepaymentmodal}>
              Cancel
            </Button>,
            ]}
            >
          <Form
              name="paymentform"
              onFinish={(event) => this.onPaymentFinish(event)}
          >

           <FormItem 
                  label="Payment Mode"
                  name="paymentmode"
                  rules={[
                    {
                      required: true,
                      message: 'Please select payment mode',
                    },
                  ]}
                  >
                      <Select 
                      value={this.state.paymentmode} 
                      onChange={this.handlepaymentmodeChange}
                      placeholder="Payment Mode" >
                            <Option value='cash'>Cash</Option>
                            <Option value='mobilemoney'>Mobile Money</Option>
                            <Option value='cheque'>Cheque</Option>
                            <Option value='electronicfundtransfer'>Electronic Fund Transfer</Option>                         
                      </Select>
            </FormItem>

            <FormItem label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount',
                },
              ]}
              
              >
                <InputNumber
                  style={{ width: 150 }} 
                  defaultValue={0.0}
                  formatter={value => `x ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\x\s?|(,*)/g, '')}
                />    
                            
              </FormItem>
              
              {
                this.state.paymentmode=="mobilemoney"?

                <div>
                <FormItem 
                label="Phone From"
                name="phonenumberfrom"
                rules={[
                  {
                    required: false,
                    message: 'Please input Phone',
                  },
                ]}
                
                >
                      <PhoneInput
                            countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry="UG"
                            placeholder="Enter phone"
                            />
                </FormItem> 

                <FormItem 
                label="Phone To"
                name="phonenumberto"
                rules={[
                  {
                    required: false,
                    message: 'Please input Phone',
                  },
                ]}
                
                >
                      <PhoneInput
                            countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry="UG"
                            placeholder="Enter phone"
                            />
                </FormItem> 
                </div>
                :
                <br></br>
              }

                {
                this.state.paymentmode=="electronicfundtransfer"               
                ?
                <div>

              <FormItem label="Account To"
                name="accountto"
                rules={[
                  {
                    required: false,
                    message: 'Please input account',
                  },
                ]}
              
              >
                <Input placeholder="Account To"  />
              </FormItem>
          
              <FormItem label="Client Bank"
                name="clientbank"
                rules={[
                  {
                    required: false,
                    message: 'Please input bank',
                  },
                ]}
              
              >
                <Input placeholder="Client Bank"  />
              </FormItem>
          
          
              <FormItem label="Transaction Reference"
                name="transreference"
                rules={[
                  {
                    required: false,
                    message: 'Please input reference',
                  },
                ]}
              
              >
                <Input placeholder="Reference"  />
              </FormItem>

                </div>

                :
                <br></br>

              }


              {
                this.state.paymentmode=="cheque"                
                ?
                <div>

              <FormItem label="Cheque Number"
                name="chequeno"
                rules={[
                  {
                    required: false,
                    message: 'Please input cheque number',
                  },
                ]}
              
              >
                <Input placeholder="Cheque Number"  />
              </FormItem>
        
                </div>

                :
                <br></br>

              }


            <FormItem label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please input comments',
              },
            ]}
            
            >
                <TextArea
                  placeholder="Comment here"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
            </FormItem>

            <FormItem>
              <Button  type="primary" htmlType="submit">Add Payment</Button>
            </FormItem>
            </Form>

      </Modal>
                  
        </div>

    )
    }
   
  }
}

export default SalesReceiptView; 
