import React from 'react';
import { Form, Input, Button,Select,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''

class ProductForm extends React.Component {

  state = {
    productName:'' ,  
    unit:'',
    unitprice:'',
    productDescription:'',
    productSerial:'',
    productunits:[],
    datarequested:true,
  }


  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


   axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }


    axios.get(serverconfig.backendserverurl+`/api/productunits/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          productunits:res.data
        })

        this.setState({datarequested:false})

    })

}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    let form_data = new FormData();

    form_data.append('product_name', this.state.productName);
    form_data.append('unit', this.state.unit);
    form_data.append('unit_price', this.state.unitprice);
    form_data.append('description', this.state.productDescription);
    form_data.append('sacco', sacco);

    axios.post(serverconfig.backendserverurl+'/api/products/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => this.setState({datarequested:false}),window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handleProductNameChange= (e) => this.setState({ productName: e.target.value});
  handleunitChange= (e) => this.setState({ unit: e});
  handleunitpriceChange= (e) => this.setState({ unitprice: e.target.value});
  handleProductDescriptionChange= (e) => this.setState({ productDescription: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form onFinish={(event) => this.handleFormSubmit(event)}>
      <FormItem label="Product Name"
          name="productname"
          rules={[
            {
              required: true,
              message: 'Please input name',
            },
          ]}     
      >
        <Input name="productname"  placeholder="Put a name here." value={this.state.productName} onChange={this.handleProductNameChange} />
      </FormItem>
  
      <FormItem label="Product Unit"
          name="productunit"
          rules={[
            {
              required: true,
              message: 'Please select unit',
            },
          ]}     
      >
                <Select placeholder="Unit"  value={this.state.unit} onChange={this.handleunitChange} >
                  {this.state.productunits.map(
                    (unt)=>(
                      <Option value={unt.id}>{unt.unitname}</Option>
                    ))}
                </Select>
      </FormItem>
  

  
      <FormItem label="Unit Price"
          name="unitprice"
          rules={[
            {
              required: true,
              message: 'Please enter unit price',
            },
          ]}     
      
      >
              <Input name="unitprice" type="number" value={0} placeholder="Unit Price" value={this.state.unitprice} onChange={this.handleunitpriceChange}/>
      </FormItem>
  

      <FormItem label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Please enter description',
            },
          ]}      
      
      >
        <Input name="description"  placeholder="Description here" value={this.state.productDescription} onChange={this.handleProductDescriptionChange} />
      </FormItem>
  
      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
  
      </FormItem>
    </Form>
        </div>
      );


    }


   
  }

}


export default ProductForm;



