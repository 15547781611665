import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Switch,Popover,Popconfirm,message } from 'antd';
import { Form, Input,Select,Spin } from 'antd';
import * as reactstrp from 'reactstrap';

import {
    Col,
    Row,
  } from 'reactstrap';
import ProductForm from '../components/ProductForm'

import { UserOutlined,LoadingOutlined,DeleteOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''

class ProductDetail extends React.Component{
    canvasRef = React.createRef();

    state={
        product: {},
        serial:'',
        productName:'' ,  
        unit:'',
        unitprice:'',
        productDescription:'',
        productunits:[],
        datarequested:true,

    }

    componentDidMount(){
        if(localStorage.getItem("sacco")){
            sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
            }else{
                sacco= ''
            }
            
            if(localStorage.getItem("token")){
                token= localStorage.getItem("token")
            }else{
                token= ''
            }
      
      
            axios.defaults.headers={
              "Content-Type":"application/json",
              Authorization:`Token ${token}`
            }


        const productID=this.props.match.params.productID;
        axios.get(`${serverconfig.backendserverurl}/api/products/${productID}`)
        .then(res => {  
            this.setState({
                product: res.data
            })

            this.setState({productName:res.data.product_name})
            this.setState({unit:res.data.unit})
            this.setState({unitprice:res.data.unit_price})
            this.setState({productDescription:res.data.description})
        })

        axios.get(serverconfig.backendserverurl+`/api/productunits/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              productunits:res.data
            })

            this.setState({datarequested:false})
        })



    }

       //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/mealaddons/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


//submit button pressed
handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    const productID=this.props.match.params.productID;

    let form_data = new FormData();

    form_data.append('product_name', this.state.productName);
    form_data.append('unit', this.state.unit);
    form_data.append('unit_price', this.state.unitprice);
    form_data.append('description', this.state.productDescription);

    axios.put(serverconfig.backendserverurl+`/api/products/${productID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => this.setState({datarequested:false}),window.location.reload(false)
  )
  .catch(error => console.log(error))
  }


  handleProductNameChange= (e) => this.setState({ productName: e.target.value});
  handleunitChange= (e) => this.setState({ unit: e});
  handleunitpriceChange= (e) => this.setState({ unitprice: e.target.value});
  handleProductDescriptionChange= (e) => this.setState({ productDescription: e.target.value});

  render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
            
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.product.product_name}>
                        <p>Unit: {this.state.product.unitname}</p>
                        <p>Unit Price: {this.state.product.unit_price}</p>
                        <p>Desc: {this.state.product.description}</p>
                   
                    </Card>
                    </Col>
                    
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title="Product Update">
                    <Form
                            onFinish={(event) => this.handleFormSubmit(event)}
                    >
                        <FormItem label="Product Name">
                        <Input name="productname"  placeholder="Put a name here." value={this.state.productName} onChange={this.handleProductNameChange} />
                        </FormItem>
    
                        <FormItem label="Product Unit">
                                <Select placeholder="Unit" style={{ width: 120 }} value={this.state.unit} onChange={this.handleunitChange} >
                                    {this.state.productunits.map(
                                    (unt)=>(
                                        <Option value={unt.id}>{unt.unitname}</Option>
                                    ))}
                                </Select>
                        </FormItem>
                   
    
                        <FormItem label="Unit Price">
                            <Input name="unitprice" type="number" value={0} placeholder="Unit Price" value={this.state.unitprice} onChange={this.handleunitpriceChange}/>
                        </FormItem>

    
                        <FormItem label="Description">
                        <Input name="description"  placeholder="Description here" value={this.state.productDescription} onChange={this.handleProductDescriptionChange} />
                        </FormItem>
 
                        <FormItem>
                        <Button  type="primary" htmlType="submit">Update</Button>
                        </FormItem>
                    </Form>
    
                    </Card>  
                    </Col>
                
                    </Row>
    
                    <br/>
    
                </div>
            )


          }

    }

}

export default ProductDetail; 